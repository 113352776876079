<template>
  <div class="container honor" v-loading="loading">
    <div class="top">
      <div class="name">{{ details.name }}</div>

      <div class="tag_style cursorP" @click="toCompanyDetail">查看企业详情</div>
    </div>

    <div class="table">
      <div class="table_cell">
        <div class="label">发布时间</div>
        <div class="value">
          {{ details.time | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">项目类型</div>
        <div class="value">
          {{ details.projType | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">资金来源</div>
        <div class="value">
          {{ details.fundingSource | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">项目所在地</div>
        <div class="value">
          {{ details.place | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">项目金额（万元）</div>
        <div class="value">
          {{ details.amount | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">资质要求</div>
        <div class="value">
          {{ details.certStandard | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">业绩要求</div>
        <div class="value">
          {{ details.performanceStandard | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">人员要求</div>
        <div class="value">
          {{ details.manager | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">联合体</div>
        <div class="value">
          {{
            details.unions ? (details.unions === "0" ? "不支持" : "支持") : "--"
          }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">招标方式</div>
        <div class="value">
          {{ details.noticeType | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">公告类型</div>
        <div class="value">
          {{ details.articleType | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">建设单位</div>
        <div class="value">
          {{ details.owner | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">报名截止日期</div>
        <div class="value">
          {{ details.endTime | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">招标代理机构</div>
        <div class="value">
          {{ details.agent | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">工期（天）</div>
        <div class="value">
          {{ details.duration | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">招标来源</div>
        <div class="value">
          <div v-if="details.tenderAnnouncement.length > 0">
            <span
              v-for="item in details.tenderAnnouncement"
              class="vip"
              @click="linkToUrl(item.key)"
            >
              {{ item.value }}
            </span>
          </div>
          <span v-else>--</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* 荣誉详情 */
import { getNoticeDetail } from "@/api/detailPage";

export default {
  data() {
    return {
      id: "",
      details: { tenderAnnouncement: [] },
      loading: false,
    };
  },

  methods: {
    /* 获取数据 */
    async getData() {
      this.loading = true;
      const params = {
        id: this.id,
      };

      const { code, data, msg } = await getNoticeDetail(params);
      if (code !== 0) return this.$message.error(msg);
      this.details = data;
      this.loading = false;
    },

    /* 操作 */
    // 跳转到网页
    linkToUrl(url) {
      if (url) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
      } else {
        this.$message.warning("暂无跳转地址");
      }
    },
    // 跳转到企业详情页面
    toCompanyDetail() {
      let id = this.details.companyId;
      if (id) {
        this.$router.push({
          name: "companyDetails",
          params: { name: "荣誉信息" },
          query: {
            id,
          },
        });
      } else {
        this.$message.warning("暂无企业信息！");
      }
    },

    /* 初始化 */
    __init__() {
      this.id = this.$route.params.id;
      this.getData();
    },
  },

  created() {
    this.__init__();
  },

  activated() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.honor {
  padding: 20px;
  background-color: #fff;

  .top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .name {
      font-size: 20px;
      font-weight: bold;
      color: #333;
      flex: 1;
      margin-right: 20px;
    }
  }

  .table {
    .table_cell {
      display: flex;
      align-items: stretch;
      border: 1px solid #ecf1ff;
      border-bottom: none;
      border-right: none;
      font-size: 14px;

      &:last-child {
        border-bottom: 1px solid #ecf1ff;
      }

      .label {
        padding: 15px;
        min-width: 200px;
        text-align: center;
        color: #666;
        display: flex;
        align-items: center;
        background-color: #f5faff;
      }

      .value {
        display: flex;
        align-items: center;
        padding: 15px;
        flex: 1;
        color: #666;
        border-right: 1px solid #ecf1ff;
        border-left: 1px solid #ecf1ff;
        line-height: 1.5;
      }
    }
  }
}
</style>
